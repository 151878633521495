import { ListRootApplicationsImp } from '@/data/services/list-root-applications';
import { FirebaseApplicationDAO } from '@/infra/daos/firebase-application-dao';
var ListRootApplicationsServiceFactory = /** @class */ (function () {
    function ListRootApplicationsServiceFactory() {
    }
    ListRootApplicationsServiceFactory.create = function () {
        var firebaseApplicationDAO = new FirebaseApplicationDAO({
            apiKey: process.env.FIREBASE_API_KEY,
            appId: process.env.FIREBASE_APP_ID,
            authDomain: process.env.FIREBASE_AUTH_DOMAIN,
            projectId: process.env.FIREBASE_PROJECT_ID,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        });
        var listRootApplicationsService = new ListRootApplicationsImp({
            applicationDAO: firebaseApplicationDAO,
        });
        return listRootApplicationsService;
    };
    return ListRootApplicationsServiceFactory;
}());
export { ListRootApplicationsServiceFactory };
