export var eligibility = [
    {
        id: 10,
        orgName: '@portalempresas',
        projectName: 'life-totalizer',
        routeIsEqual: ['/portal/elegibilidade/totalizador-de-vidas'],
        routeIsDifferent: [],
        registerApplication: true,
        equalRoutes: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/life-totalizer/portalempresas-life-totalizer.js',
        },
    },
    {
        id: 11,
        orgName: '@portalempresas',
        projectName: 'individual-registration',
        routeIsEqual: ['/portal/elegibilidade/cadastro-individual'],
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/individual-registration/portalempresas-individual-registration.js',
        },
    },
    {
        id: 12,
        orgName: '@portalempresas',
        projectName: 'invalid-beneficiaries',
        routeIsEqual: ['/portal/elegibilidade/consulta-de-vidas-invalidas'],
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/invalid-beneficiaries/portalempresas-invalid-beneficiaries.js',
        },
    },
    {
        id: 13,
        orgName: '@portalempresas',
        projectName: 'lives-consult',
        routeIsEqual: ['/portal/elegibilidade/consulta-de-vidas'],
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/lives-consult/portalempresas-lives-consult.js',
        },
    },
    {
        id: 14,
        orgName: '@portalempresas',
        projectName: 'telepsicologia',
        routeIsEqual: ['/portal/telepsicologia'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://portalempresas-telepsicologia-hotfix.telemedicinaeinstein.com.br/portalempresas-telepsicologia.js',
            qas: 'https://portalempresas-telepsicologia-qas.telemedicinaeinstein.com.br/portalempresas-telepsicologia.js',
            production: 'https://portalempresas-telepsicologia.telemedicinaeinstein.com.br/portalempresas-telepsicologia.js',
            stage: 'https://portalempresas-telepsicologia.telemedicinaeinstein.com.br/portalempresas-telepsicologia.js',
            development: 'http://portalempresas-telepsicologia-frontend.s3-website-us-east-1.amazonaws.com/portalempresas-telepsicologia.js',
            uat: '',
        },
    },
    {
        id: 15,
        orgName: '@portalempresas',
        projectName: 'import-of-lives',
        routeIsEqual: ['/portal/elegibilidade/importacao-de-vidas'],
        routeIsDifferent: [],
        isTokenRequired: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/import-root/portalempresas-import-root.js',
        },
    },
    {
        id: 16,
        orgName: '@portalempresas',
        projectName: 'life-totalizer-conecta',
        routeIsEqual: ['/portal/elegibilidade/totalizador-de-vidas-conecta'],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://portalempresas-life-totalizer-conecta-frontend-qas.telemedicinaeinstein.com.br/portalempresas-life-totalizer-conecta.js',
            production: 'https://portalempresas-life-totalizer-conecta-frontend.telemedicinaeinstein.com.br/portalempresas-life-totalizer-conecta.js',
            stage: 'https://portalempresas-life-totalizer-conecta-frontend.telemedicinaeinstein.com.br/portalempresas-life-totalizer-conecta.js',
            development: 'http://portalempresas-life-totalizer-conecta-frontend-qas.s3-website-us-east-1.amazonaws.com/portalempresas-life-totalizer-conecta.js',
            uat: '',
        },
    },
    {
        id: 44,
        orgName: '@portalempresas',
        projectName: 'particular-lifes-consultation',
        routeIsEqual: ['/portal/elegibilidade/consulta-de-vidas-corporativas'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        isTokenRequired: true,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://portalempresas-particular-elegibility-consultation-htx.telemedicinaeinstein.com.br/portalempresas-particular-lifes-consultation.js',
            qas: 'https://portalempresas-particular-elegibility-consultation-qas.telemedicinaeinstein.com.br/portalempresas-particular-lifes-consultation.js',
            production: 'https://portalempresas-particular-elegibility-consultation.telemedicinaeinstein.com.br/portalempresas-particular-lifes-consultation.js',
            stage: '',
            development: 'https://portalempresas-particular-elegibility-consultation-dev.telemedicinaeinstein.com.br/portalempresas-particular-lifes-consultation.js',
            uat: 'https://portalempresas-particular-elegibility-consultation-uat.telemedicinaeinstein.com.br/portalempresas-particular-lifes-consultation.js',
        },
    },
    {
        id: 47,
        orgName: '@portalempresas',
        equalRoutes: true,
        isTokenRequired: true,
        projectName: 'update-validity',
        routeIsEqual: ['/portal/elegibilidade/atualizacao-de-vigencia'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/update-validity/portalempresas-update-validity.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/update-validity/portalempresas-update-validity.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/update-validity/portalempresas-update-validity.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/update-validity/portalempresas-update-validity.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/update-validity/portalempresas-update-validity.js',
        },
    },
];
