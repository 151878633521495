export var management = [
    {
        id: 24,
        orgName: '@portalempresas',
        projectName: 'vouchers',
        routeIsEqual: [
            '/portal/gerenciamento/vouchers',
            '/portal/gerenciamento/vouchers/cadastro',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-vouchers-microfront-htx.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
            qas: 'https://pe-vouchers-microfront-qas.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
            production: 'https://pe-vouchers-microfront-prd.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
            stage: 'https://portalempresas-vouchers-frontend.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
            development: 'https://pe-vouchers-microfront-dev.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
            uat: 'https://pe-vouchers-microfront-uat.telemedicinaeinstein.com.br/portalempresas-vouchers.js',
        },
    },
    {
        id: 25,
        orgName: '@portalempresas',
        projectName: 'user-registration-manager-v2',
        routeIsEqual: [
            '/portal/gerenciamento/usuarios',
            '/portal/gerenciamento/usuarios/',
            '/portal/gerenciamento/usuarios/cadastro',
            '/portal/gerenciamento/usuarios/cadastro/',
            '/portal/gerenciamento/usuarios/editar/',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-user-registration-manager-htx.telemedicinaeinstein.com.br/portalempresas-user-registration-manager.js',
            qas: 'https://pe-user-registration-manager-qas.telemedicinaeinstein.com.br/portalempresas-user-registration-manager.js',
            production: 'https://pe-user-registration-manager-prd.telemedicinaeinstein.com.br/portalempresas-user-registration-manager.js',
            stage: '',
            development: 'https://pe-user-registration-manager-dev.telemedicinaeinstein.com.br/portalempresas-user-registration-manager.js',
            uat: 'https://pe-user-registration-manager-uat.telemedicinaeinstein.com.br/portalempresas-user-registration-manager.js',
        },
    },
    {
        id: 27,
        orgName: '@portalempresas',
        projectName: 'status-de-agendamento',
        routeIsEqual: ['/portal/gerenciamento/status-de-agendamento'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/operacoes/agendamentos/portalempresas-status-de-agendamento.js',
        },
    },
    {
        id: 28,
        orgName: '@portalempresas',
        projectName: 'clients',
        routeIsEqual: ['/portal/gerenciamento/clientes'],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/clients/portalempresas-clients.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/clients/portalempresas-clients.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/clients/portalempresas-clients.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/clients/portalempresas-clients.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/clients/portalempresas-clients.js',
        },
    },
    {
        id: 51,
        orgName: '@portalempresas',
        projectName: 'contracts-root',
        routeIsEqual: [
            '/portal/gerenciamento/contratos/criar',
            '/portal/gerenciamento/contratos/editar',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/contracts-root/portalempresas-contracts-root.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/contracts-root/portalempresas-contracts-root.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/contracts-root/portalempresas-contracts-root.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/contracts-root/portalempresas-contracts-root.js',
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/contracts-root/portalempresas-contracts-root.js',
            stage: '',
        },
    },
    {
        id: 29,
        orgName: '@portalempresas',
        projectName: 'contracts',
        routeIsEqual: ['/portal/gerenciamento/contratos'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/contracts/portalempresas-contracts.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/contracts/portalempresas-contracts.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/contracts/portalempresas-contracts.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/contracts/portalempresas-contracts.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/contracts/portalempresas-contracts.js',
        },
    },
    {
        id: 52,
        orgName: '@portalempresas',
        projectName: 'files-management',
        routeIsEqual: ['/portal/old/gerenciamento/arquivos'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-file-management-htx.telemedicinaeinstein.com.br/portalempresas-files-management.js',
            qas: 'https://pe-file-management-qas.telemedicinaeinstein.com.br/portalempresas-files-management.js',
            production: 'https://pe-file-management-prd.telemedicinaeinstein.com.br/portalempresas-files-management.js',
            stage: '',
            development: 'https://pe-file-management-dev.telemedicinaeinstein.com.br/portalempresas-files-management.js',
            uat: 'https://pe-file-management-uat.telemedicinaeinstein.com.br/portalempresas-files-management.js',
        },
    },
    {
        id: 140,
        orgName: '@portalempresas',
        projectName: 'profile-registration',
        routeIsEqual: ['/portal/gerenciamento/perfis/criacao'],
        private: true,
        validateToken: true,
        clearStorage: false,
        startRoute: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/profile-registration/portalempresas-profile-registration.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/profile-registration/portalempresas-profile-registration.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/profile-registration/portalempresas-profile-registration.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/profile-registration/portalempresas-profile-registration.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/profile-registration/portalempresas-profile-registration.js',
        },
    },
    {
        id: 139,
        orgName: '@portalempresas',
        projectName: 'profiles-management',
        routeIsEqual: ['/portal/gerenciamento/perfis'],
        routeStartsWith: '/portal/gerenciamento/perfis',
        private: true,
        validateToken: true,
        clearStorage: false,
        startRoute: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: false,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/profile-management/portalempresas-profiles-management.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/profile-management/portalempresas-profiles-management.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/profile-management/portalempresas-profiles-management.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/profile-management/portalempresas-profiles-management.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/profile-management/portalempresas-profiles-management.js',
        },
    },
    {
        id: 141,
        orgName: '@portalempresas',
        projectName: 'queue-control',
        routeStartsWith: '/portal/gerenciamento/filas',
        routeIsEqual: [],
        startRoute: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            production: 'https://pe-main-prd-dev.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
        },
    },
    {
        id: 60,
        orgName: '@portalempresas',
        projectName: 'terms',
        routeIsEqual: ['/portal/gerenciamento/historico-termos'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
            stage: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
            uat: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/terms-microfrontend/portalempresas-terms.js',
        },
    },
    {
        id: 142,
        orgName: '@portalempresas',
        projectName: 'term-management',
        routeStartsWith: '/portal/gerenciamento/termos',
        routeIsEqual: [],
        startRoute: true,
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            production: 'https://pe-main-prd-dev.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/term-management/portalempresas-term-management.js',
        },
    },
];
