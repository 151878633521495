import { verify, sign } from 'jsonwebtoken';
var JwtEncoder = /** @class */ (function () {
    function JwtEncoder(secret) {
        this.secret = secret;
    }
    JwtEncoder.prototype.decode = function (value) {
        return verify(value, this.secret);
    };
    JwtEncoder.prototype.encode = function (value, expireTimeInMinutes) {
        var hasExpireTime = typeof expireTimeInMinutes === 'number';
        var expiresInParam = hasExpireTime
            ? {
                expiresIn: this.minutesToSeconds(expireTimeInMinutes),
            }
            : null;
        return sign(value, this.secret, expiresInParam);
    };
    JwtEncoder.prototype.minutesToSeconds = function (minutes) {
        return minutes * 60;
    };
    return JwtEncoder;
}());
export { JwtEncoder };
