import toastr from 'toastr';
import { ErrorMessages } from './error-messages';
var BrowserRouter = /** @class */ (function () {
    function BrowserRouter() {
        var _this = this;
        this.messages = ErrorMessages;
        window.addEventListener('load', function () {
            var params = new URLSearchParams(window.location.search);
            if (!params.get('message'))
                return;
            toastr.error(_this.messages[params.get('message')]);
        });
    }
    BrowserRouter.getInstance = function () {
        if (!this.instance) {
            this.instance = new BrowserRouter();
        }
        return this.instance;
    };
    BrowserRouter.prototype.navigate = function (location, messageCode) {
        var isValidMessageCode = messageCode && messageCode in this.messages;
        var messageParam = isValidMessageCode ? "?message=".concat(messageCode) : '';
        window.location.replace("".concat(location).concat(messageParam));
    };
    BrowserRouter.prototype.refresh = function () {
        window.location.reload();
    };
    return BrowserRouter;
}());
export { BrowserRouter };
