export var dashboards = [
    {
        id: 8,
        orgName: '@portalempresas',
        projectName: 'truclinic-dashboard',
        routeIsEqual: ['/portal/relatorios/truclinic'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: true,
        isTokenRequired: true,
        equalRoutes: true,
        environments: {
            hotfix: '',
            qas: 'https://portalempresas-truclinic-dashboard-qas.telemedicinaeinstein.com.br/portalempresas-truclinic-dashboard.js',
            production: 'https://portalempresas-truclinic-dashboard.telemedicinaeinstein.com.br/portalempresas-truclinic-dashboard.js',
            stage: 'https://portalempresas-truclinic-dashboard.telemedicinaeinstein.com.br/portalempresas-truclinic-dashboard.js',
            development: '',
            uat: '',
        },
    },
    {
        id: 9,
        orgName: '@portalempresas',
        projectName: 'default-dashboard',
        routeStartsWith: '/portal/relatorios/powerbi',
        routeIsEqual: [],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/dados/powerbi-embedded/portalempresas-default-dashboard.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/dados/powerbi-embedded/portalempresas-default-dashboard.js',
            stage: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/powerbi-embedded/portalempresas-default-dashboard.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/powerbi-embedded/portalempresas-default-dashboard.js',
            uat: '',
        },
    },
    {
        id: 43,
        orgName: '@portalempresas',
        projectName: 'upa-encaminhamento',
        routeIsEqual: ['/portal/relatorios/upa-encaminhamento'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: '',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/dados/dash-upa-digital/portalempresas-upa-encaminhamento.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/dados/dash-upa-digital/portalempresas-upa-encaminhamento.js',
            stage: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/dash-upa-digital/portalempresas-upa-encaminhamento.js',
            development: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/dados/dash-upa-digital/portalempresas-upa-encaminhamento.js',
            uat: '',
        },
    },
    {
        id: 46,
        orgName: '@portalempresas',
        projectName: 'checkup-dashboard',
        routeIsEqual: ['/portal/relatorios/checkup-dashboard'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/dados/checkup-dashboard/portalempresas-checkup-dashboard.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/dados/checkup-dashboard/portalempresas-checkup-dashboard.js',
            stage: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/checkup-dashboard/portalempresas-checkup-dashboard.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/checkup-dashboard/portalempresas-checkup-dashboard.js',
            uat: '',
        },
    },
    {
        id: 49,
        orgName: '@portalempresas',
        projectName: 'relatorio-einstein-conecta',
        routeIsEqual: ['/portal/relatorios/relatorio-einstein-conecta'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: '',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/dados/relatorio-einstein-conecta/portalempresas-report-einsteinconecta.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/dados/relatorio-einstein-conecta/portalempresas-report-einsteinconecta.js',
            stage: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/relatorio-einstein-conecta/portalempresas-report-einsteinconecta.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/relatorio-einstein-conecta/portalempresas-report-einsteinconecta.js',
            uat: '',
        },
    },
    {
        id: 51,
        orgName: '@portalempresas',
        projectName: 'clinicaseinstein-dashboard',
        routeIsEqual: ['/portal/relatorios/clinicas-einstein'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/dados/clinicaseinstein-dashboard/portalempresas-clinicaseinstein-dashboard.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/dados/clinicaseinstein-dashboard/portalempresas-clinicaseinstein-dashboard.js',
            stage: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/clinicaseinstein-dashboard/portalempresas-clinicaseinstein-dashboard.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/dados/clinicaseinstein-dashboard/portalempresas-clinicaseinstein-dashboard.js',
            uat: '',
        },
    },
];
