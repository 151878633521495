import appConstants from '@/configs/constants/app-constants';
import { WindowEventBus } from '@/infra/event-bus/window-event-bus';
import { UserIdleTimerImp } from '@/presentation/services/user-idle-timer/user-idle-timer';
var UserIdleTimerFactory = /** @class */ (function () {
    function UserIdleTimerFactory(appSessionManager) {
        this.appSessionManager = appSessionManager;
    }
    UserIdleTimerFactory.prototype.create = function () {
        return new UserIdleTimerImp({
            eventBus: WindowEventBus.getInstance(),
            userEvents: ['click', 'focus', 'hover', 'input', 'keypress', 'mousemove'],
            loginPaths: ['/login', '/login/recover-password'],
            applicationSessionManager: this.appSessionManager,
            userTypeExpireTime: {
                enterprise: appConstants.enterpriseExpireTimeInMinutes,
                healthAssist: appConstants.internalExpireTimeInMinutes,
            },
        });
    };
    return UserIdleTimerFactory;
}());
export { UserIdleTimerFactory };
