export var inCompany = [
    {
        id: 40,
        orgName: '@portalempresas',
        projectName: 'incompany-campaigns',
        routeIsEqual: [
            '/portal/incompany/campaigns',
            '/portal/incompany/campaigns/view',
            '/portal/incompany/campaign/lifes/exams',
            '/portal/incompany/create-campaign',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://campaigns-htx.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
            qas: 'https://campaigns-qas.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
            production: 'https://campaigns-prd.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
            stage: 'https://campaigns-qas.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
            uat: 'https://campaigns-uat.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
            development: 'https://campaigns-dev.telemedicinaeinstein.com.br/portalempresas-incompany-campaigns.js',
        },
    },
];
