export var vaccinesClub = [
    {
        id: 38,
        orgName: '@portalempresas',
        projectName: 'vaccines-club-subscribers',
        routeIsEqual: [
            '/portal/elegibilidade/clube-de-vacinas/consulta-de-assinantes',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/clubeDeVacinas/subscribes/portalempresas-vaccines-club-subscribers.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/clubeDeVacinas/subscribes/portalempresas-vaccines-club-subscribers.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/clubeDeVacinas/subscribes/portalempresas-vaccines-club-subscribers.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/clubeDeVacinas/subscribes/portalempresas-vaccines-club-subscribers.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/clubeDeVacinas/subscribes/portalempresas-vaccines-club-subscribers.js',
        },
    },
    {
        id: 39,
        orgName: '@portalempresas',
        projectName: 'vaccines-club-call-center',
        routeIsEqual: [
            '/portal/gerenciamento/clube-de-vacinas/consulta-de-call-center',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        isTokenRequired: true,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/clubeDeVacinas/callCenter/portalempresas-vaccines-club-call-center.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/clubeDeVacinas/callCenter/portalempresas-vaccines-club-call-center.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/clubeDeVacinas/callCenter/portalempresas-vaccines-club-call-center.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/clubeDeVacinas/callCenter/portalempresas-vaccines-club-call-center.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/clubeDeVacinas/callCenter/portalempresas-vaccines-club-call-center.js',
        },
    },
    {
        id: 40,
        orgName: '@portalempresas',
        projectName: 'vaccines-club-notifications-management',
        routeIsEqual: ['/portal/gerenciamento/clube-de-vacinas/notificacoes'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/clubeDeVacinas/notificationsManagement/portalempresas-vaccines-club-notifications-management.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/clubeDeVacinas/notificationsManagement/portalempresas-vaccines-club-notifications-management.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/clubeDeVacinas/notificationsManagement/portalempresas-vaccines-club-notifications-management.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/clubeDeVacinas/notificationsManagement/portalempresas-vaccines-club-notifications-management.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/clubeDeVacinas/notificationsManagement/portalempresas-vaccines-club-notifications-management.js',
        },
    },
    {
        id: 45,
        orgName: '@portalempresas',
        projectName: 'vaccines-club-subscribers-manager',
        routeIsEqual: [
            '/portal/gerenciamento/clube-de-vacinas/gerenciador-de-assinantes',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/clubeDeVacinas/subscribersManager/portalempresas-vaccines-club-subscribers-manager.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/clubeDeVacinas/subscribersManager/portalempresas-vaccines-club-subscribers-manager.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/clubeDeVacinas/subscribersManager/portalempresas-vaccines-club-subscribers-manager.js',
            stage: '',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/clubeDeVacinas/subscribersManager/portalempresas-vaccines-club-subscribers-manager.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/clubeDeVacinas/subscribersManager/portalempresas-vaccines-club-subscribers-manager.js',
        },
    },
];
