export var schedule = [
    {
        id: 29,
        orgName: '@portalempresas',
        projectName: 'service-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-servicos',
            '/portal/cadastros/catalogo-de-servicos/criar-servico',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/service-catalog/portalempresas-service-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/service-catalog/portalempresas-service-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/service-catalog/portalempresas-service-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/service-catalog/portalempresas-service-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/service-catalog/portalempresas-service-catalog.js',
        },
    },
    {
        id: 30,
        orgName: '@portalempresas',
        projectName: 'showcase-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-vitrines',
            '/portal/cadastros/catalogo-de-vitrines/preview',
            '/portal/cadastros/catalogo-de-vitrines/aprovar',
            '/portal/cadastros/catalogo-de-vitrines/criar-vitrine',
            '/portal/cadastros/catalogo-de-vitrines/criar-landing-page',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: '',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/showcase-catalog/portalempresas-showcase-catalog.js',
            production: '',
            stage: '',
            development: '',
            uat: '',
        },
    },
    {
        id: 31,
        orgName: '@portalempresas',
        projectName: 'categories-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-categorias',
            '/portal/cadastros/catalogo-de-categorias/criar-categoria',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/category-catalog/portalempresas-categories-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/category-catalog/portalempresas-categories-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/category-catalog/portalempresas-categories-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/category-catalog/portalempresas-categories-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/category-catalog/portalempresas-categories-catalog.js',
        },
    },
    {
        id: 32,
        orgName: '@portalempresas',
        projectName: 'products-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-produtos',
            '/portal/cadastros/catalogo-de-produtos/criar-produto',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/product-catalog/portalempresas-products-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/product-catalog/portalempresas-products-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/product-catalog/portalempresas-products-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/product-catalog/portalempresas-products-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/product-catalog/portalempresas-products-catalog.js',
        },
    },
    {
        id: 33,
        orgName: '@portalempresas',
        projectName: 'rules-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-regras',
            '/portal/cadastros/catalogo-de-regras/cadastrar-regra',
        ],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/rule-catalog/portalempresas-rules-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/rule-catalog/portalempresas-rules-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/rule-catalog/portalempresas-rules-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/rule-catalog/portalempresas-rules-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/rule-catalog/portalempresas-rules-catalog.js',
        },
    },
    {
        id: 34,
        orgName: '@portalempresas',
        projectName: 'units-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-unidades',
            '/portal/cadastros/catalogo-de-unidades/cadastrar-unidade',
        ],
        private: true,
        isTokenRequired: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/unit-catalog/portalempresas-units-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/unit-catalog/portalempresas-units-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/unit-catalog/portalempresas-units-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/unit-catalog/portalempresas-units-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/unit-catalog/portalempresas-units-catalog.js',
        },
    },
    {
        id: 35,
        orgName: '@portalempresas',
        projectName: 'business-area-catalog',
        routeIsEqual: [
            '/portal/cadastros/catalogo-de-areas',
            '/portal/cadastros/catalogo-de-areas/criar-area',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/business-area/portalempresas-business-area-catalog.js',
            production: '',
            stage: '',
            development: '',
            uat: '',
        },
    },
    {
        id: 36,
        orgName: '@portalempresas',
        projectName: 'history-management',
        routeIsEqual: ['/portal/catalogo/log'],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: '',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/log-catalog/portalempresas-history-management.js',
            production: '',
            stage: '',
            development: '',
            uat: '',
        },
    },
    {
        id: 41,
        orgName: '@portalempresas',
        projectName: 'specialists-catalog',
        routeStartsWith: '/portal/cadastros/catalogo-de-especialistas',
        routeIsEqual: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        startRoute: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/specialist-catalog/portalempresas-specialists-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/specialist-catalog/portalempresas-specialists-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/specialist-catalog/portalempresas-specialists-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/specialist-catalog/portalempresas-specialists-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/specialist-catalog/portalempresas-specialists-catalog.js',
        },
    },
    {
        id: 142,
        orgName: '@portalempresas',
        projectName: 'mailing-catalog',
        routeIsEqual: ['/portal/cadastros/catalogo-de-mailings'],
        routeStartsWith: '/portal/cadastros/catalogo-de-mailings',
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        startRoute: true,
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/mailing-catalog/portalempresas-mailing-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/mailing-catalog/portalempresas-mailing-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/mailing-catalog/portalempresas-mailing-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/mailing-catalog/portalempresas-mailing-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/mailing-catalog/portalempresas-mailing-catalog.js',
        },
    },
    {
        id: 143,
        orgName: '@portalempresas',
        projectName: 'logs-catalog',
        routeIsEqual: ['/portal/cadastros/catalogo-de-logs'],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/logs-catalog/portalempresas-logs-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/logs-catalog/portalempresas-logs-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/logs-catalog/portalempresas-logs-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/logs-catalog/portalempresas-logs-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/logs-catalog/portalempresas-logs-catalog.js',
        },
    },
    {
        id: 144,
        orgName: '@portalempresas',
        projectName: 'specialties-catalog',
        routeStartsWith: '/portal/cadastros/catalogo-de-especialidades',
        routeIsEqual: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        isTokenRequired: true,
        startRoute: true,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://catalog-root-htx.telemedicinaeinstein.com.br/specialties-catalog/portalempresas-specialties-catalog.js',
            qas: 'https://portalempresas-catalog-qas.telemedicinaeinstein.com.br/specialties-catalog/portalempresas-specialties-catalog.js',
            production: 'https://catalog-root.telemedicinaeinstein.com.br/specialties-catalog/portalempresas-specialties-catalog.js',
            stage: '',
            development: 'https://catalog-root-dev.telemedicinaeinstein.com.br/specialties-catalog/portalempresas-specialties-catalog.js',
            uat: 'https://catalog-root-uat.telemedicinaeinstein.com.br/specialties-catalog/portalempresas-specialties-catalog.js',
        },
    },
];
