export var telemedicine = [
    {
        id: 37,
        orgName: '@portalempresas',
        projectName: 'acionamentos',
        routeIsEqual: ['/portal/telemedicina/acionamentos'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/operacoes/acionamentos-microfrontend/portalempresas-acionamentos.js',
        },
    },
];
