import { LogoutEvent } from '@/domain/events/logout-event';
var UserIdleTimerImp = /** @class */ (function () {
    function UserIdleTimerImp(dependencies) {
        this.maxIdleTimeInMiliseconds = this.parseMinutesToMiliseconds(30);
        this.eventBus = dependencies.eventBus;
        this.userEvents = dependencies.userEvents;
        this.loginPaths = dependencies.loginPaths;
        this.applicationSessionManager = dependencies.applicationSessionManager;
        this.userTypeExpireTime = dependencies.userTypeExpireTime;
    }
    UserIdleTimerImp.prototype.start = function () {
        this.setupUserIdleTimer();
        this.recordUserLastActionTimestamp();
        this.subscribeUserEvents();
    };
    UserIdleTimerImp.prototype.setupUserIdleTimer = function () {
        var _this = this;
        if (this.userIdleTimer)
            clearTimeout(this.userIdleTimer);
        if (!this.isLoginArea()) {
            this.setMaxIdleTime();
            this.userIdleTimer = setTimeout(function () {
                var isUserIdle = _this.isUserIdle();
                if (!isUserIdle)
                    return;
                _this.publishLogoutEvent();
            }, this.maxIdleTimeInMiliseconds);
        }
    };
    UserIdleTimerImp.prototype.setMaxIdleTime = function () {
        var isEnterprise = this.applicationSessionManager.getUserSession().isEnterprise;
        var idleTimeInMinutes = this.getMaxIdleTimeInMinutesBasedOnProfileType(isEnterprise);
        var idleTimeInMiliseconds = this.parseMinutesToMiliseconds(idleTimeInMinutes);
        this.maxIdleTimeInMiliseconds = idleTimeInMiliseconds;
    };
    UserIdleTimerImp.prototype.getMaxIdleTimeInMinutesBasedOnProfileType = function (isEnterprise) {
        if (isEnterprise)
            return this.userTypeExpireTime.enterprise;
        return this.userTypeExpireTime.healthAssist;
    };
    UserIdleTimerImp.prototype.recordUserLastActionTimestamp = function () {
        if (this.isLoginArea())
            return;
        var currentTimeInMiliseconds = this.getCurrentTimeInMiliseconds();
        localStorage.setItem('userLastActionTimestamp', currentTimeInMiliseconds.toString());
    };
    UserIdleTimerImp.prototype.parseMinutesToMiliseconds = function (minutes) {
        return minutes * 60 * 1000;
    };
    UserIdleTimerImp.prototype.subscribeUserEvents = function () {
        var _this = this;
        this.userEvents.forEach(function (userEvent) {
            window.addEventListener(userEvent, function () {
                if (_this.isLoginArea())
                    return;
                if (_this.isUserIdle()) {
                    _this.publishLogoutEvent();
                    return;
                }
                _this.recordUserLastActionTimestamp();
                _this.setupUserIdleTimer();
            });
        });
    };
    UserIdleTimerImp.prototype.isUserIdle = function () {
        if (this.isLoginArea())
            return false;
        var lastUserActionTimestamp = this.getUserLastActionTimestamp();
        var currentTimeInMiliseconds = this.getCurrentTimeInMiliseconds();
        var timeDifference = currentTimeInMiliseconds - lastUserActionTimestamp;
        return timeDifference >= this.maxIdleTimeInMiliseconds;
    };
    UserIdleTimerImp.prototype.publishLogoutEvent = function () {
        console.log("Deslogando usu\u00E1rio. Perfil ".concat(this.applicationSessionManager.getUserSession().isEnterprise
            ? 'externo'
            : 'interno', " deslogado em ").concat(this.maxIdleTimeInMiliseconds, "ms"));
        this.eventBus.publish(new LogoutEvent({
            message: 'Sua sessão foi expirada devido ao tempo de inatividade. Por favor, realize um novo login.',
            refreshSession: false,
        }));
    };
    UserIdleTimerImp.prototype.isLoginArea = function () {
        var isLoginArea = this.loginPaths.includes(window.location.pathname);
        return isLoginArea;
    };
    UserIdleTimerImp.prototype.getUserLastActionTimestamp = function () {
        var userLastActionTimestamp = localStorage.getItem('userLastActionTimestamp');
        return Number(userLastActionTimestamp);
    };
    UserIdleTimerImp.prototype.getCurrentTimeInMiliseconds = function () {
        return new Date().getTime();
    };
    return UserIdleTimerImp;
}());
export { UserIdleTimerImp };
