export var paymentApplication = [
    {
        id: 124,
        orgName: '@portalempresas',
        projectName: 'payment-bonds',
        routeIsEqual: [
            '/portal/paperless/contratos',
            '/portal/paperless/titulo-pagamento',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        startRoute: true,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
            stage: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/portalempresas-payment-bonds.js',
        },
    },
];
