var RedirectEnvironments;
(function (RedirectEnvironments) {
    RedirectEnvironments["qas"] = "qas";
    RedirectEnvironments["prod"] = "prod";
})(RedirectEnvironments || (RedirectEnvironments = {}));
var RedirectHandler = /** @class */ (function () {
    function RedirectHandler() {
        var _a;
        this.environmentsUrl = (_a = {},
            _a[RedirectEnvironments.qas] = 'https://portalempresas-front-qas.telemedicinaeinstein.com.br',
            _a[RedirectEnvironments.prod] = process.env.CLIENT_DEFAULT_URL,
            _a);
    }
    RedirectHandler.prototype.handle = function () {
        var shouldNotRedirect = this.isLocalHost() ||
            this.isEinsteinDomain() ||
            (this.isNewUrl() && !this.isTelemedicinaProduction());
        if (shouldNotRedirect)
            return;
        var hostEnv = window.location.hostname.match(/qas/);
        var envKey = hostEnv ? hostEnv[0] : RedirectEnvironments.prod;
        window.location.assign(this.environmentsUrl[envKey]);
    };
    RedirectHandler.prototype.isNewUrl = function () {
        return !!window.location.hostname.match(/front/);
    };
    RedirectHandler.prototype.isTelemedicinaProduction = function () {
        return !!window.location.hostname.match(/front-prd/);
    };
    RedirectHandler.prototype.isLocalHost = function () {
        return window.location.hostname === 'localhost';
    };
    RedirectHandler.prototype.isEinsteinDomain = function () {
        return window.location.hostname === process.env.CLIENT_HOST;
    };
    return RedirectHandler;
}());
export { RedirectHandler };
