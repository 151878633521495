var UserSessionModelAssembler = /** @class */ (function () {
    function UserSessionModelAssembler() {
    }
    UserSessionModelAssembler.assembly = function (user) {
        var _this = this;
        return {
            _id: user.id_v1,
            id_v2: user.id,
            email: user.email,
            login: user.username,
            nome: user.name,
            user_in_active_directory: user.user_in_active_directory,
            id_convenio: this.getUserInsurance(user.access),
            perfis: user.access.map(function (access) { return _this.createUserProfile(access); }),
            id_perfil: this.createUserProfile(user.access[0]),
        };
    };
    UserSessionModelAssembler.getUserInsurance = function (accesses) {
        var healthInsurances = new Map();
        accesses.forEach(function (access) {
            access.health_insurance_data.forEach(function (healthInsurance) {
                var isHealthInsuranceMapped = healthInsurances.has(healthInsurance.portal_empresas_id);
                if (isHealthInsuranceMapped)
                    return;
                healthInsurances.set(healthInsurance.portal_empresas_id, healthInsurance);
            });
        });
        if (healthInsurances.size === 0)
            return null;
        var firstHealthInsurance = Array.from(healthInsurances.values())[0];
        return firstHealthInsurance.portal_empresas_id;
    };
    UserSessionModelAssembler.createUserProfile = function (access) {
        return {
            _id: access.profile.externalId,
            profileUuid: access.profile.externalId,
            isEnterprise: access.profile.isEnterprise,
            isHealthAssist: access.profile.isHealthAssist,
        };
    };
    return UserSessionModelAssembler;
}());
export { UserSessionModelAssembler };
