export var history = [
    {
        id: 17,
        orgName: '@portalempresas',
        projectName: 'logs',
        routeIsEqual: ['/portal/logs'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/logs/portalempresas-logs.js',
        },
    },
    {
        id: 18,
        orgName: '@portalempresas',
        projectName: 'utilization-report',
        routeIsEqual: [
            '/portal/historico/taxa-utilizacao',
            '/portal/historico/taxa-de-utilizacao',
        ],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://portalempresas-utilization-report-hotfix.telemedicinaeinstein.com.br/portalempresas-utilization-report.js',
            qas: 'https://portalempresas-utilization-report-qas.telemedicinaeinstein.com.br/portalempresas-utilization-report.js',
            production: 'https://portalempresas-utilization-report-prd.telemedicinaeinstein.com.br/portalempresas-utilization-report.js',
            stage: 'https://portalempresas-utilization-report-prd.telemedicinaeinstein.com.br/portalempresas-utilization-report.js',
            development: 'http://portalempresas-utilization-report-frontend-hotfix.s3-website-us-east-1.amazonaws.com/portalempresas-utilization-report.js',
            uat: '',
        },
    },
    {
        id: 19,
        orgName: '@portalempresas',
        projectName: 'import-history',
        routeIsEqual: ['/portal/historico/importacao-de-vidas'],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/import-history/portalempresas-import-history.js',
        },
    },
    {
        id: 20,
        orgName: '@portalempresas',
        projectName: 'publication-history',
        routeIsEqual: ['/portal/historico/publicacoes'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
            stage: 'https://pe-main-mfe-stg.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/operacoes/publication-history/portalempresas-publication-history.js',
        },
    },
    {
        id: 21,
        orgName: '@portalempresas',
        projectName: 'telecoms-eligibility-history',
        routeIsEqual: ['/portal/historico/telecoms/elegibilidade'],
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/telecoms-eligibility-history/portalempresas-telecoms-eligibility-history.js',
        },
    },
    {
        id: 22,
        orgName: '@portalempresas',
        projectName: 'telecoms-transaction-history',
        routeIsEqual: ['/portal/historico/telecoms/transacoes'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        isTokenRequired: true,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/telecoms-transaction-history/portalempresas-telecoms-transaction-history.js',
        },
    },
    {
        id: 23,
        orgName: '@portalempresas',
        projectName: 'clinic-appointments',
        routeIsEqual: ['/portal/historico/atendimentos-clinicas'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        isTokenRequired: true,
        equalRoutes: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/operacoes/clinic-appointments/portalempresas-clinic-appointments.js',
        },
    },
    {
        id: 42,
        orgName: '@portalempresas',
        projectName: 'tuss-appointments',
        routeIsEqual: ['/portal/historico/relatorio-tuss'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
            stage: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
            uat: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/elegibilidade/tuss-appointments/portalempresas-tuss-appointments.js',
        },
    },
];
