export var vinculacaoClient = [
    {
        id: 61,
        orgName: '@hiae',
        projectName: 'care-team',
        routeStartsWith: '/portal/elegibilidade/times-de-cuidado',
        routeIsEqual: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        startRoute: true,
        blockInProduction: true,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://cr-microfront-htx.telemedicinaeinstein.com.br/hiae-care-team.js',
            qas: 'https://cr-microfront-qas.telemedicinaeinstein.com.br/hiae-care-team.js',
            production: 'https://cr-microfront-prd.telemedicinaeinstein.com.br/hiae-care-team.js',
            stage: 'https://cr-microfront-prd.telemedicinaeinstein.com.br/hiae-care-team.js',
            development: 'https://cr-microfront-dev.telemedicinaeinstein.com.br/hiae-care-team.js',
            uat: 'https://cr-microfront-uat.telemedicinaeinstein.com.br/hiae-care-team.js',
        },
    },
];
