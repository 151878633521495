import { SpaAplication } from '@/presentation/spa-application';
var SpaApplicationFactory = /** @class */ (function () {
    function SpaApplicationFactory(applicationSessionManager, listRootApplicationsService) {
        this.applicationSessionManager = applicationSessionManager;
        this.listRootApplicationsService = listRootApplicationsService;
    }
    SpaApplicationFactory.prototype.createSpaApplication = function () {
        return new SpaAplication(this.applicationSessionManager, this.listRootApplicationsService, JSON.parse(process.env.APPLICATIONS_SECTIONS));
    };
    return SpaApplicationFactory;
}());
export { SpaApplicationFactory };
