import { JwtEncoder } from '@/infra';
import { JwtGzipEncoder } from '@/infra/enconder/jwt-gzip-enconder';
var JwtEnconderFactory = /** @class */ (function () {
    function JwtEnconderFactory() {
    }
    JwtEnconderFactory.prototype.create = function (type) {
        switch (type) {
            case 'default': {
                return new JwtEncoder(process.env.SECRET);
            }
            case 'gzip': {
                return new JwtGzipEncoder(process.env.SECRET);
            }
            default: {
                return new JwtEncoder(process.env.SECRET);
            }
        }
    };
    return JwtEnconderFactory;
}());
export { JwtEnconderFactory };
