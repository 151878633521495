import { verify, sign } from 'jsonwebtoken';
import { gzipSync, gunzipSync } from 'zlib';
var JwtGzipEncoder = /** @class */ (function () {
    function JwtGzipEncoder(secret) {
        this.secret = secret;
    }
    JwtGzipEncoder.prototype.decode = function (value) {
        var zipValue = Buffer.from(value, 'base64');
        var uncompressedValue = gunzipSync(zipValue).toString('utf-8');
        return verify(uncompressedValue, this.secret);
    };
    JwtGzipEncoder.prototype.encode = function (value, expireTimeInMinutes) {
        var hasExpireTime = typeof expireTimeInMinutes === 'number';
        var expiresInParam = hasExpireTime
            ? {
                expiresIn: this.minutesToSeconds(expireTimeInMinutes),
            }
            : null;
        var token = sign(value, this.secret, expiresInParam);
        var compressed = gzipSync(token);
        return compressed.toString('base64');
    };
    JwtGzipEncoder.prototype.minutesToSeconds = function (minutes) {
        return minutes * 60;
    };
    return JwtGzipEncoder;
}());
export { JwtGzipEncoder };
