import Cookie from 'universal-cookie';
var UniversalCookiesAdapter = /** @class */ (function () {
    function UniversalCookiesAdapter(appCookies) {
        this.appCookies = appCookies;
        this.cookiesRepository = new Cookie();
        this.setDomain();
    }
    UniversalCookiesAdapter.prototype.setDomain = function () {
        this.domain = this.getDomain(window.location.hostname);
    };
    UniversalCookiesAdapter.prototype.getDomain = function (hostname) {
        var splittedDomain = hostname.split('.');
        var domainLength = splittedDomain.length;
        var isDomainInSaasFormat = domainLength > 4;
        var lastThreeDomainValuesIndex = domainLength - 3;
        var secondDomainValueIndex = 1;
        var startIndex = isDomainInSaasFormat
            ? lastThreeDomainValuesIndex
            : secondDomainValueIndex;
        return splittedDomain.slice(startIndex, domainLength).join('.');
    };
    UniversalCookiesAdapter.prototype.get = function (key) {
        var _a;
        return (_a = this.cookiesRepository.get(key)) !== null && _a !== void 0 ? _a : null;
    };
    UniversalCookiesAdapter.prototype.set = function (params) {
        var options = {
            path: '/',
            domain: this.domain,
            sameSite: true,
        };
        if (params.expireTimeInMinutes) {
            Object.assign(options, {
                expires: this.getExpireDate(params.expireTimeInMinutes),
            });
        }
        this.cookiesRepository.set(params.key, params.value, options);
    };
    UniversalCookiesAdapter.prototype.getExpireDate = function (minutes) {
        var date = new Date();
        var currentTime = date.getTime();
        var expireTime = currentTime + 1000 * (minutes * 60);
        date.setTime(expireTime);
        return date;
    };
    UniversalCookiesAdapter.prototype.remove = function (key) {
        this.cookiesRepository.remove(key, {
            path: '/',
            domain: this.domain,
            sameSite: true,
        });
    };
    UniversalCookiesAdapter.prototype.removeAll = function () {
        var _this = this;
        var cookies = Object.values(this.appCookies);
        cookies.forEach(function (cookie) {
            _this.remove(cookie);
        });
    };
    return UniversalCookiesAdapter;
}());
export { UniversalCookiesAdapter };
