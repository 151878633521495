import { UniversalCookiesAdapter } from '@/infra';
import { appCookies } from '@/main/constants';
var UniversalCookiesFactory = /** @class */ (function () {
    function UniversalCookiesFactory() {
    }
    UniversalCookiesFactory.prototype.create = function () {
        return new UniversalCookiesAdapter(appCookies);
    };
    return UniversalCookiesFactory;
}());
export { UniversalCookiesFactory };
