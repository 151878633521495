import { navigateToUrl } from 'single-spa';
import toastr from 'toastr';
import { ErrorMessages } from './error-messages';
var SingleSpaRouter = /** @class */ (function () {
    function SingleSpaRouter() {
        this.messages = ErrorMessages;
    }
    SingleSpaRouter.getInstance = function () {
        if (!this.instance) {
            this.instance = new SingleSpaRouter();
        }
        return this.instance;
    };
    SingleSpaRouter.prototype.navigate = function (location, messageKey) {
        if (messageKey) {
            toastr.error(this.messages[messageKey]);
        }
        navigateToUrl(location);
    };
    SingleSpaRouter.prototype.refresh = function () {
        navigateToUrl(window.location.href);
    };
    return SingleSpaRouter;
}());
export { SingleSpaRouter };
