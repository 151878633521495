import location from 'location-href';
export function createTTL(daysToExpire, init) {
    if (init === void 0) {
        init = new Date();
    }
    var expireDate = init.setDate(init.getDate() + daysToExpire);
    return expireDate;
}
export function acceptCookies() {
    var daysToExpire = 30;
    localStorage.setItem('portal-empresas-acceptCookies', new Date(createTTL(daysToExpire)).toString());
}
export function getAcceptCookie() {
    return localStorage.getItem('portal-empresas-acceptCookies');
}
export function hasAcceptanceCookiesInvalid() {
    var hasAcceptCookies = getAcceptCookie();
    if (!hasAcceptCookies)
        return true;
    try {
        return new Date(hasAcceptCookies) < new Date();
    }
    catch (error) {
        console.error('Erro ao tentar calcular data de expiração', error);
    }
}
export function hiddenElement(element) {
    element.style.transition = 'opacity ease .5s';
    element.style.opacity = '0';
    element.style.bottom = '-100%';
}
export function cookiesAcceptModal() {
    try {
        var cookiesAcceptModal_1 = document.getElementById('cookies-accept-modal');
        if (cookiesAcceptModal_1 === null)
            throw new Error('Elemento #cookies-accept-modal não encontrado.');
        if (hasAcceptanceCookiesInvalid())
            cookiesAcceptModal_1.classList.remove('cookies-accept-modal--hidden');
        cookiesAcceptModal_1
            .getElementsByClassName('cookies-accept-modal__btn--accept')[0]
            .addEventListener('click', function () {
            acceptCookies();
            hiddenElement(cookiesAcceptModal_1);
        });
        cookiesAcceptModal_1
            .getElementsByClassName('cookies-accept-modal__btn--read-more')[0]
            .addEventListener('click', function () {
            var currentPage = location.href;
            var encodedURI = encodeURI(currentPage);
            location.set("".concat(process.env.TERMS_OF_USE_URL, "?back=").concat(encodedURI));
        });
    }
    catch (error) {
        console.error('Erro ao tentar criar modal responsável por cookies', error);
    }
}
