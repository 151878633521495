var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
            if (ar || !(i in from)) {
                if (!ar)
                    ar = Array.prototype.slice.call(from, 0, i);
                ar[i] = from[i];
            }
        }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { accident } from './accident';
import { faturamentoBoletim } from './boletins';
import { vinculacaoClient } from './care-team';
import { common } from './common';
import { dashboards } from './dashboards';
import { eligibility } from './eligibility';
import { faturamentoApplications } from './faturamento';
import { fileManager } from './file-manager';
import { history } from './history';
import { inCompany } from './in-company';
import { management } from './management';
import { nursingCare } from './nursing-care';
import { paymentApplication } from './payment';
import { schedule } from './schedule';
import { telemedicine } from './telemedicine';
import { vaccinesClub } from './vaccines-club';
export var applications = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], common, true), dashboards, true), eligibility, true), history, true), management, true), schedule, true), telemedicine, true), faturamentoApplications, true), faturamentoBoletim, true), vaccinesClub, true), inCompany, true), accident, true), fileManager, true), nursingCare, true), vinculacaoClient, true), paymentApplication, true);
