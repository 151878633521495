export var faturamentoApplications = [
    {
        id: 48,
        orgName: '@portalempresas',
        projectName: 'atendimentos-clinicas-faturamento',
        routeIsEqual: ['/portal/faturamento/atendimentos'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-atendimentos-faturamentos-ce-htx.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
            qas: 'https://pe-atendimentos-faturamentos-ce-qas.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
            production: 'https://pe-atendimentos-faturamentos-ce-prd.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
            stage: 'https://pe-atendimentos-faturamentos-ce-qas.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
            development: 'https://pe-atendimentos-faturamentos-ce-dev.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
            uat: 'https://pe-atendimentos-faturamentos-ce-uat.telemedicinaeinstein.com.br/portalempresas-atendimentos-clinicas-faturamento.js',
        },
    },
];
