export var nursingCare = [
    {
        id: 104,
        orgName: '@portalempresas',
        projectName: 'nursing-care',
        routeIsEqual: ['/portal/telemedicina/enfermagem-einstein-conecta'],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        routeIsDifferent: [],
        equalRoutes: true,
        registerApplication: true,
        isTokenRequired: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
            stage: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/operacoes/nursing-care-microfrontend/portalempresas-nursing-care.js',
        },
    },
];
