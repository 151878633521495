export var faturamentoBoletim = [
    {
        id: 50,
        orgName: '@portalempresas',
        projectName: 'measurement-bulletin',
        routeStartsWith: '/portal/faturamento/boletim',
        routeIsEqual: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        startRoute: true,
        blockInProduction: false,
        isTokenRequired: true,
        routeIsDifferent: [],
        equalRoutes: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-measurement-bulletin-mfe-htx.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
            qas: 'https://pe-measurement-bulletin-mfe-qas.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
            production: 'https://pe-measurement-bulletin-mfe-prd.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
            stage: 'https://pe-measurement-bulletin-mfe-qas.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
            development: 'https://pe-measurement-bulletin-mfe-dev.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
            uat: 'https://pe-measurement-bulletin-mfe-uat.telemedicinaeinstein.com.br/portalempresas-measurement-bulletin.js',
        },
    },
];
