var saasApp = JSON.parse(process.env.SAAS_COMMON_APPLICATION);
export var common = Object.assign([
    {
        id: 0,
        orgName: '@portalempresas',
        projectName: 'exceptions',
        routeIsDifferent: [],
        routeIsEqual: [],
        equalRoutes: false,
        private: false,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: false,
        isTokenRequired: true,
        environments: {
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/exceptions/portalempresas-exceptions.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/exceptions/portalempresas-exceptions.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/exceptions/portalempresas-exceptions.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/exceptions/portalempresas-exceptions.js',
            hotfix: 'https://pe-main-mfe-hotfix.telemedicinaeinstein.com.br/onboarding/exceptions/portalempresas-exceptions.js',
            stage: 'https://portalempresas-exceptions-stg.telemedicinaeinstein.com.br/portalempresas-exceptions.js',
        },
    },
    {
        id: 1,
        orgName: '@portalempresas',
        projectName: 'root-config',
        routeIsDifferent: [],
        routeIsEqual: [],
        equalRoutes: true,
        private: false,
        validateToken: false,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: false,
        isTokenRequired: true,
        environments: {
            hotfix: '/portalempresas-root-config.js',
            qas: '/portalempresas-root-config.js',
            production: '/portalempresas-root-config.js',
            stage: '/portalempresas-root-config.js',
            development: '/portalempresas-root-config.js',
            uat: '/portalempresas-root-config.js',
        },
    },
    {
        id: 2,
        orgName: '@portalempresas',
        projectName: 'navbar-v2',
        routeIsDifferent: ['/signiiin', '/signout', '/', '/signin', '/login'],
        routeIsEqual: [],
        private: false,
        equalRoutes: false,
        validateToken: false,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-navbar-htx.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
            qas: 'https://pe-navbar-qas.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
            production: 'https://pe-navbar.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
            stage: 'https://pe-navbar-stg.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
            development: 'https://pe-navbar-dev.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
            uat: 'https://pe-navbar-uat.telemedicinaeinstein.com.br/portalempresas-navbar-v2.js',
        },
    },
    {
        id: 3,
        orgName: '@portalempresas',
        projectName: 'footer',
        routeIsDifferent: ['/signiiin', '/signout', '/', '/signin', '/login'],
        routeIsEqual: [],
        equalRoutes: false,
        private: true,
        validateToken: false,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/footer/portalempresas-footer.js',
        },
    },
    {
        id: 4,
        orgName: '@portalempresas',
        projectName: 'authentication',
        routeIsEqual: ['/signiiin', '/signout', '/', '/signin', '/login'],
        routeIsDifferent: [],
        private: false,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: true,
        isTokenRequired: false,
        equalRoutes: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/authentication/portalempresas-authentication.js',
        },
    },
    {
        id: 5,
        orgName: '@portalempresas',
        projectName: 'environment-flag',
        routeIsDifferent: ['/signiiin', '/signout', '/', '/signin', '/login'],
        routeIsEqual: [],
        equalRoutes: false,
        private: true,
        validateToken: false,
        clearStorage: false,
        isTokenRequired: true,
        blockInProduction: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://portalempresas-environment-flag.telemedicinaeinstein.com.br/portalempresas-environment-flag.js',
            qas: 'https://portalempresas-environment-flag.telemedicinaeinstein.com.br/portalempresas-environment-flag.js',
            production: '',
            stage: 'https://portalempresas-environment-flag.telemedicinaeinstein.com.br/portalempresas-environment-flag.js',
            development: 'https://portalempresas-environment-flag-frontend-qas.s3.amazonaws.com/portalempresas-environment-flag.js',
            uat: 'https://portalempresas-environment-flag.telemedicinaeinstein.com.br/portalempresas-environment-flag.js',
        },
    },
    {
        id: 6,
        orgName: '@portalempresas',
        projectName: 'my-account',
        routeIsEqual: [
            '/portal/minha-conta/editar-perfil/usuario',
            '/portal/minha-conta/editar-perfil/perfil',
            '/portal/minha-conta/editar-perfil/redefinir-senha',
        ],
        routeIsDifferent: [],
        equalRoutes: true,
        private: true,
        validateToken: true,
        isTokenRequired: true,
        clearStorage: false,
        blockInProduction: false,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/my-account/portalempresas-my-account.js',
        },
    },
    {
        id: 7,
        orgName: '@portalempresas',
        equalRoutes: true,
        projectName: 'home',
        routeIsEqual: ['/portal/inicio'],
        routeIsDifferent: [],
        private: true,
        validateToken: true,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: true,
        registerApplication: true,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/home/portalempresas-home.js',
        },
    },
    {
        id: 1808,
        orgName: '@portalempresas',
        projectName: 'feedback-widget',
        equalRoutes: false,
        routeIsEqual: [],
        routeIsDifferent: [],
        private: false,
        validateToken: false,
        clearStorage: false,
        blockInProduction: false,
        isTokenRequired: false,
        registerApplication: false,
        environments: {
            hotfix: 'https://pe-main-mfe-htx.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
            qas: 'https://pe-main-mfe-qas.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
            production: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
            stage: 'https://pe-main-mfe-prd.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
            development: 'https://pe-main-mfe-dev.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
            uat: 'https://pe-main-mfe-uat.telemedicinaeinstein.com.br/onboarding/feedback-widget/portalempresas-feedback-widget.js',
        },
    },
], saasApp);
