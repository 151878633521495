import appConstants from '@/configs/constants/app-constants';
import { ApplicationSessionManagerImp } from '@/data/manager/application-session-manager';
import { BrowserRouter } from '@/infra/router/browser-router';
var ApplicationSessionManagerFactory = /** @class */ (function () {
    function ApplicationSessionManagerFactory(cookiesFactory, enconderFactory) {
        this.cookiesFactory = cookiesFactory;
        this.enconderFactory = enconderFactory;
    }
    ApplicationSessionManagerFactory.prototype.createApplicationSessionManager = function () {
        var cookies = this.cookiesFactory.create();
        var encoder = this.enconderFactory.create(process.env.ENCODER_TYPE);
        return new ApplicationSessionManagerImp({
            router: BrowserRouter.getInstance(),
            cookies: cookies,
            encoder: encoder,
            cookiesExpireTimeInMinutes: appConstants.cookiesExpireTimeInMinutes,
        });
    };
    return ApplicationSessionManagerFactory;
}());
export { ApplicationSessionManagerFactory };
